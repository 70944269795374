import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

              <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span>
                Coinsclone For SushiSwap clone Script?</h3>
              <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img width="468px" height="439px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/why-choose-coinsclone-for-sushiswap-clone-script.png" alt="Why Choose our SushiSwap clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is a world-class DeFi exchange script provider in the blockchain space and we have years of experience in offering best-in-class clone script services at a moderate cost. Want to set up the most reliable DeFi exchange on the Ethereum blockchain like SushiSwap with cutting-edge functionalities? Then you can partner with Coinsclone. We offer ideal DeFi exchange clone script development services for all budding startups and entrepreneurs with impeccable outcomes.
              </p>
              <p className="pharagraph mb-0">Coinsclone’s premium SushiSwap clone software is open for optimization so you can personalize the script from top to bottom with ease. We have a pool of well-trained blockchain developers, so we can build you a 100% decentralized DeFi exchange platform as per your needs and business concepts. Additionally, We can also provide you with the most astounding UI/UX design for your web portal as well as a mobile app. So far we have successfully completed and delivered 350+ projects to our reputed clients which are based on crypto and blockchain. Therefore, we guarantee you on-time project delivery and 24/7 customer support for all our products.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose