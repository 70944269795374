import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Sushiswap/Banner"
import Whatis from "../components/Sushiswap/Whatis"
//import QuickviewSection from "../components/Sushiswap/Quickview"
import HowOur from "../components/Sushiswap/HowOur"
import TopFeatures from "../components/Sushiswap/TopFeatures"
import CoreFeatures from "../components/Sushiswap/CoreFeatures"
import SecurityofOur from "../components/Sushiswap/SecurityofOur"
import TechStack from "../components/Sushiswap/TechStack"
import FaqSection from "../components/Sushiswap/FaqSection"
import WhyShould from "../components/Sushiswap/WhyShould"
import DevApproach from "../components/Sushiswap/DevApproach"
import WhyChoose from "../components/Sushiswap/WhyChoose"
import Addon from "../components/Sushiswap/Addon"
import Testimonial from "../components/Sushiswap/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"





const Clone = () => (
  <section className="landing clone">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>SushiSwap clone Script | SushiSwap clone Software - Coinsclone</title>
        <meta name="description" content="SushiSwap clone Script is a ready-made DeFi Exchange Clone script built on the Ethereum network that performs similar to SushiSwap. Get a free demo now!" />
        <meta property="keywords" content="Whitelabel SushiSwap clone Script, Create DeFi based DEX like SushiSwap, Whitelabel SushiSwap clone Software, SushiSwap Exchange Clone" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="SushiSwap clone Script | SushiSwap clone Software - Coinsclone" />
        <meta property="og:description" content="SushiSwap clone Script is a ready-made DeFi Exchange Clone script built on the Ethereum network that performs similar to SushiSwap. Get a free demo now!" />
        <meta property="og:url" content="https://www.coinsclone.com/sushiswap-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/sushiswap-clone-script.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="SushiSwap clone Script is a ready-made DeFi Exchange Clone script built on the Ethereum network that performs similar to SushiSwap. Get a free demo now!" />
        <meta name="twitter:title" content="SushiSwap clone Script | SushiSwap clone Software - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/sushiswap-clone-script.png" />
        <link rel="canonical" href="https://www.coinsclone.com/sushiswap-clone-script/" />
      </Helmet>
      <Banner />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | SushiSwap clone Script </span>
        </div>
      </div>
      <Whatis />
      {/* <QuickviewSection /> */}
      <HowOur />
      <WhyShould />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <WhyChoose />
      <DevApproach />
      <Testimonial />
      <TechStack />
      <FaqSection />
      <UserFriendly />
      
    </Layout>
  </section>
)

export default Clone
